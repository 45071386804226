import Leaderboard from "./Leaderboard";

function App() {
  return (
    <div className="flex flex-col font-['Ubuntu'] bg-[#101115] text-white">
      <Leaderboard/>
    </div>
  );
}

export default App;
