import { useState, useEffect, useRef } from "react"
import Papa from 'papaparse'
import LeadersList from "./LeadersList"



export default function Leaderboard() {
    const [items, setItems] = useState([])
    const [visibleItems, setVisibleItems] = useState([])
    const [activeButton, setActiveButton] = useState('allTime')
    const [loadedCount, setLoadedCount] = useState(10); 
    const loader = useRef(null); 

    useEffect(() => {
        fetchData('/assets/allTime.csv', 'allTime')
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, { threshold: 1.0 })
        if (loader.current) {
            observer.observe(loader.current)
        }
        return () => {
            if (loader.current) {
                observer.unobserve(loader.current)
            }
        };
    }, [loader, visibleItems])

    function fetchData(file, buttonId) {
        fetch(file)
            .then(response => response.text())
            .then(text => {
                Papa.parse(text, {
                    header: true,
                    dynamicTyping: true,
                    complete: (results) => {
                        console.log(results.data)
                        setItems(results.data)
                        setVisibleItems(results.data.slice(0, 10))
                        setLoadedCount(10)
                        setActiveButton(buttonId)
                    }
                });
            })
            .catch(error => console.error('Error fetching the CSV file:', error))
    }

    function handleObserver(entities) {
        const target = entities[0]
        if (target.isIntersecting) {
            loadMore();
        }
    }

    function loadMore() {
        const nextLoadedCount = loadedCount + 10;
        setVisibleItems(items.slice(0, nextLoadedCount))
        setLoadedCount(nextLoadedCount)
    }

    return (
        <div className="flex flex-col items-between h-fit min-h-[100vh] mx-40 mt-20 gap-10 mobile:mx-10">
            <div className="flex flex-col justify-between gap-2">
                <h1 className="text-3xl font-bold">Leaderboard</h1>
                <p className="text-[#848486]">Compete with friends to top the charts</p>
            </div>
            <div className="flex items-center gap-4">
                <button className={activeButton === 'allTime' ? 'font-bold rounded-full px-4 py-[10px] bg-[#252429]' : 'rounded-full px-4 py-[10px]'} onClick={() => fetchData('/assets/allTime.csv', 'allTime')}>All time</button>
                <button className={activeButton === '24h' ? 'font-bold rounded-full px-4 py-[10px] bg-[#252429]' : 'rounded-full px-4 py-[10px]'} onClick={() => fetchData('/assets/24h.csv', '24h')}>24h</button>
                <button className={activeButton === '7d' ? 'font-bold rounded-full px-4 py-[10px] bg-[#252429]' : 'rounded-full px-4 py-[10px]'} onClick={() => fetchData('/assets/7d.csv', '7d')}>7d</button>
                <button className={activeButton === '1m' ? 'font-bold rounded-full px-4 py-[10px] bg-[#252429]' : 'rounded-full px-4 py-[10px]'} onClick={() => fetchData('/assets/1m.csv', '1m')}>1m</button>
            </div>
            <div id="leaders">
                <LeadersList leaders={visibleItems}></LeadersList>
                <div ref={loader} className="loader" />
            </div>
        </div>
    );
}