import User from "./User"

export default function LeadersList({leaders}) {
    return(
        <div className="flex flex-col">
            <div className="flex flex-col w-full">
                {leaders && leaders.sort(function(a, b) {
                    return a.rank - b.rank
                }).map(leader => {
                    return <User name={leader.name} pnl={leader.pnl} rank={leader.rank} />
                })}
            </div>
        </div>
        
        
    )
}